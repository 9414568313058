<template>
  <div>
    <div class="breadcrumb mt-4">
      <router-link
        to="/mass-text/phone-lists"
        class="mt-4 inline text-2xl font-semibold text-gray-600"
      >
        Phone Lists
      </router-link>
      <span class="text-base inline font-semibold text-gray-800">
        / {{ contact_group.contact_group_label }}
      </span>
    </div>

    <div class="bg-white mt-5 p-4">
      <div class="text-2xl  text-gray-600 ">
        {{ !isEditable ? contact_group.contact_group_label : "" }}

        <form
          class="inline-block"
          v-if="isEditable"
          @submit.prevent="saveUpdatedName"
        >
          <input
            class="border-gray-400 border-2 text-base p-1 w-60"
            required
            v-model="temp_contact_group.contact_group_label"
          />
          <input
            type="submit"
            class="text-sm text-blue-400 cursor-pointer ml-2 bg-white"
            value="Save"
          />
        </form>

        <span
          class="text-sm text-blue-400 cursor-pointer"
          v-if="!isEditable"
          @click="changeState(contact_group)"
        >
          Edit
        </span>

        <span
          class="text-sm text-red-400 cursor-pointer ml-1 inline"
          v-if="isEditable"
          @click="changeState(contact_group)"
        >
          Cancel
        </span>
      </div>

      <button
        class="bg-gray-700 text-white py-2 px-4 flex items-center rounded-full mt-4"
        @click="openAddPhoneModal()"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="plus"
          class="svg-inline--fa fa-plus fa-w-14 inline font-light mr-3"
          width="20px"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            stroke-width="1%"
            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
        Add Contact Number
      </button>
    </div>

    <div class="my-4 text-gray-700 font-semibold">Number</div>

    <div class="bg-white border-t-2 border-gray-300">
      <div v-if="phoneNumbers.length == 0" class="text-center py-4">
        No phone numbers yet.
        <span @click="openAddPhoneModal()" class="text-blue-400 cursor-pointer">
          Add one
        </span>
      </div>
      <div
        class="flex justify-between border-gray-100 border-b-2 p-3"
        v-for="phoneNumber in phoneNumbers"
        :key="phoneNumber"
      >
        <div>{{ phoneNumber }}</div>
        <div>
          <a
            class="text-red-400 cursor-pointer"
            @click="confirmDeleteNumber(phoneNumber)"
          >
            Delete
          </a>
        </div>
      </div>
    </div>

    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="showDeletionPopup || showPopup"
    ></div>
    <!-- deleting contact number confirmation popup -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showDeletionPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Are you sure to delete
            <span class="text-blue-400">
              {{ phoneNumber }}
            </span>
          </div>
          <form @submit.prevent="deleteNumber">
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-24 md:mr-3 text-center"
                type="submit"
              >
                Yes
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-24 md:ml-3 md:mt-0"
                @click="showDeletionPopup = false"
              >
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- add phone number modal -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Add Phone Number
          </div>
          <form @submit.prevent="addPhoneNumber" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="flex flex-col mb-6 w-full mt-2">
                <textarea
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  placeholder="Phone Number"
                  @keypress="onlyNumber"
                  v-model="phoneNumber"
                  name="contact_group_label"
                  autocomplete="off"
                  required
                  id="contact_group_label"
                >
                </textarea>
              </div>
              <div class="text-sm mb-5">
                Note: You can use comma(,) as separator for multiple input of
                numbers
              </div>
            </div>
            <div role="alert" class="my-3" v-if="errorMsg">
              <div
                class="border border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700"
              >
                <p>{{ errorMsg }}.</p>
              </div>
            </div>

            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{
                  isSubmitted ? "Adding Phone Number..." : "Add Phone Number"
                }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const firebase = require("../firebaseConfig");

export default {
  name: "SinglePhoneComponent",
  data: () => ({
    contact_group_id: "",
    temp_contact_group: {},
    contact_group: {},
    phoneNumbers: [],
    errorMsg: null,
    isSubmitted: false,
    phoneNumber: "",
    showPopup: false,
    showDeletionPopup: false,
    isEditable: false,
  }),
  mounted() {
    this.contact_group_id = this.$route.params.contact_group_id;
    this.$emit("");
    this.getContactGroup();
    this.getPhoneNumbers();
  },
  methods: {
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot((doc) => {
              resolve({
                id: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    async getContactGroup() {
      const business = await this.getBusinessInfo();

      firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .doc(this.contact_group_id)
        .onSnapshot((doc) => {
          this.contact_group = {
            id: doc.id,
            ...doc.data(),
          };
        });
    },
    async getPhoneNumbers() {
      const business = await this.getBusinessInfo();

      firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .doc(this.contact_group_id)
        .onSnapshot((doc) => {
          if (doc.data().contact_number) {
            this.phoneNumbers = [...doc.data().contact_number];
          }
        });
    },
    confirmDeleteNumber(phone) {
      this.phoneNumber = phone;
      this.showDeletionPopup = true;
    },
    async deleteNumber() {
      const index = this.phoneNumbers.indexOf(this.phoneNumber);
      const business = await this.getBusinessInfo();
      if (index > -1) this.phoneNumbers.splice(index, 1);

      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .doc(this.contact_group_id)
        .update({
          contact_number: this.phoneNumbers,
        });
      this.getPhoneNumbers();
      this.showDeletionPopup = false;
      this.phoneNumber = "";
    },
    async addPhoneNumber() {
      const business = await this.getBusinessInfo();
      let hasError = null;
      this.isSubmitted = true;

      const phoneNumbers = this.phoneNumber
        .split(/\r\n|\n|\r/)
        .join(",")
        .split(",");

      let allPhoneNumbers = this.phoneNumbers.concat(phoneNumbers);

      allPhoneNumbers = allPhoneNumbers.map((phoneNumber) =>
        phoneNumber.replace(/[^0-9]/g, "").replace(/^1/g, "")
      );

      console.info("allphones", allPhoneNumbers);

      this.phoneNumbers = Array.from(new Set(allPhoneNumbers));

      this.phoneNumbers = this.phoneNumbers.filter((phoneNumber) => {
        if (phoneNumber.length != 10) {
          hasError = `Phone number ${phoneNumber} has to be exactly 10 digit`;
          return false;
        } else {
          return phoneNumber;
        }
      });

      console.info("sanitized numbes", phoneNumbers);
      this.errorMsg = hasError;

      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .doc(this.contact_group_id)
        .update({
          contact_number: this.phoneNumbers,
        });

      if (!this.errorMsg) this.showPopup = false;
      this.isSubmitted = false;
    },
    openAddPhoneModal(phone) {
      this.phoneNumber = phone;
      this.errorMsg = null;
      this.showPopup = true;
    },
    onlyNumber($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 44) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async saveUpdatedName() {
      const business = await this.getBusinessInfo();
      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .doc(this.contact_group_id)
        .update({
          contact_group_label: this.temp_contact_group.contact_group_label,
        });
      this.isEditable = !this.isEditable;
    },
    changeState(contactGroup) {
      this.isEditable = !this.isEditable;
      this.temp_contact_group = { ...contactGroup };
    },
  },
};
</script>
