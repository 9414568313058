import { render } from "./SinglePhoneComponent.vue?vue&type=template&id=73614647"
import script from "./SinglePhoneComponent.vue?vue&type=script&lang=js"
export * from "./SinglePhoneComponent.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "73614647"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('73614647', script)) {
    api.reload('73614647', script)
  }
  
  module.hot.accept("./SinglePhoneComponent.vue?vue&type=template&id=73614647", () => {
    api.rerender('73614647', render)
  })

}

script.__file = "src/components/SinglePhoneComponent.vue"

export default script